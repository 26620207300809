//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
  > a {
    color: var(--primaryText);
  }
  + .breadcrumb-item {
    &::before {
      padding: 0 5px;
      content: '\f105' !important;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
    }
  }
}
