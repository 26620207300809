// Alert
.alert-modal {
  position: absolute;
  z-index: 2000;
  margin-left: auto;
  width: 100%;
  margin-top: 5%;

  & .modal-content {
    box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.6);
    width: fit-content;
    margin: auto;
  }
  & .modal-header {
    color: white;
    height: 40px;
  }
  & .modal-header > h4 {
    margin: -5px auto;
    font-size: 22px;
    font-weight: 300;
  }
  & .modal-body {
    height: fit-content;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: center;
  }
  & .btn-secondary {
    background-color: $white;
    border-color: $white;
    color: $black;
    font-weight: 300;
  }
  & .btn-secondary:hover {
    background-color: $alertModal;
  }
  & .modal-footer {
    padding-bottom: 3px;
    padding-top: 3px;
    height: fit-content;
  }
}
.warning .modal-header {
  background-color: $alertWarning;
}
.error .modal-header {
  background-color: $alertError;
}
.success {
  color: $green;
}

.success .modal-header {
  background-color: $green;
}

// AlertBar

.alert-bar {
  position: fixed !important;
  top: 9%;
  left: 35%;
  min-width: 35%;
  max-width: 40%;
}

// ConfirmationModal

.confirmation-modal-content {
  & .modal-body {
    padding: 10px 20px;
    font-size: 17px;
    max-width: 550px;
    height: 100px;
  }
  & .modal-content {
    margin: 20% auto;
    max-width: 550px;
    box-shadow: 0 0 1000px rgb(39, 39, 39);
  }
}
.modal-header {
  border-color: var(--borderPrimary) !important;
}

// CreationWindow
.input-row {
  margin-bottom: 15px;
}
.creation-modal-window .modal-content {
  min-width: 800px;
  & .modal-header-row {
    margin: 0;
  }
}
.creation-window-body {
  padding: 20px 30px;
}
.display-more {
  min-width: 170px;
}

// GenericWindow

.edit-wrapper {
  margin: 20px;
  &.row {
    margin-left: 0px !important;
  }
}
.editData-row {
  margin: 5px 0;

  & button {
    padding: 4px 10px;
  }
}
.editRow-defaultMargin {
  margin-left: 20px !important;
  & div {
    padding: 0;
  }
}
.rendered-field-text {
  overflow-wrap: break-word;
  margin: 6px 0;
}
.edit-input-fields {
  font-size: 1rem;
  .editData-row select option {
    background-color: var(--inputPrimary) !important;
    outline: none !important;
  }
}
.render-field_button {
  & button {
    font-size: 25px !important;
    margin: 10px 4rem 0 0 !important;
  }
}

.toolbar-elements {
  display: flex;
  justify-content: space-around;
  margin-left: 10%;
}
.btn-toolbar {
  margin-left: 5%;
  color: $black;
}
.btn-icon {
  margin-right: 5px;
}
.btn-handler {
  display: flex;
  max-height: 30px;
  width: 30px;
  background-color: #fff;
  justify-content: center;
  font-size: 15px !important;
}
.btn-handler:hover {
  background-color: #adb0b15e;
}
.edit-input_important {
  color: red;
}
.select-input {
  padding: 2px 15px;
}
.select-option {
  height: 200px;
}
.checkbox-group {
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
  width: 30%;
}
.form-control:focus {
  border: #66686996 1px solid;
}
.form-check {
  & input[type='checkbox'] {
    width: 1rem;
    margin-top: -17px;
  }
}
select {
  pointer-events: all !important;
  cursor: default !important;
}
.handler-button {
  outline: none !important;
  border: none !important;
  background: none !important;
  color: var(--primaryText) !important;
  padding: 0 !important;
  color: var(--primaryText) !important;
  margin: 0 5px 8px 0 !important;
}
.rendered-field {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 25px;
  outline: none;
  cursor: text;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: #ced4da 1px solid !important;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  &_color {
    height: 6px !important;
    cursor: pointer !important;
  }
  &_link {
    color: $blue !important;
  }
  &-group {
    padding-left: 24px;
    width: 100%;
    & * {
      padding: 0 !important;
    }
    & label {
      font-weight: inherit;
      cursor: pointer;
    }
  }
  &_enum {
    padding: 0 !important;
    font-size: 15px !important;
    margin-bottom: 10px !important;
    height: 0;
  }
  &_textAreaPlain {
    padding: 15px !important;
  }
  &-text {
    font-size: 0.75rem;
    margin-left: 0rem;
    white-space: pre-wrap;
  }
  &_document {
    text-decoration: underline !important;
    cursor: pointer !important;
  }
}
.mth_textAreaPlain {
  height: 100% !important;
  border: #0066d4 1px solid !important;
  border-radius: 0 !important;
  background-color: inherit !important;
  color: var(--primaryText) !important;
  min-height: 100px !important;
}
.input-checkbox {
  margin-left: 20%;
}
.icon-btn {
  margin-left: 15px;
  cursor: pointer;
  font-size: 25px;
}
.svg-fill :first-child {
  fill: $blue;
}
.btn-editing {
  display: none;
}

.btn-primary > svg {
  margin-right: 6px;
  margin-bottom: 4px;
}

.display-more {
  width: 70%;
}

.modal-content {
  background-color: var(--secondaryBg) !important;
  border-color: var(--borderPrimary) !important;
  box-shadow: 0 0 400px rgb(39, 39, 39);
}
.listModal_table {
  #table-fullGrid {
    height: 50vh;
  }
}
#modal-list {
  outline: none !important;
}
.editing-modal {
  &-body {
    padding: 0;
    overflow-y: auto;
  }
  &-select_col {
    width: 60%;
  }
  &-caption {
    margin: 15px 0 5px 0;
  }
}
.modal-list {
  padding: 10px 20px;
  &-header {
    border-bottom: 1px solid #d6d6d6;
  }
}
.modal-horizontal-scroll {
  overflow: auto;
  max-height: 300px;
}
.modal-search {
  margin: 15px 25px 0 0;
  width: 40%;
  &-text {
    margin: 5px 0 0 10px;
  }
}
.modal-select-option {
  text-align: start;
  padding-left: 18px !important;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.option-row {
  padding: 10px 0;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 102, 212, 0.1);
  }
}

// Header

.header {
  height: 70px;
  background-color: $blue;
  min-height: 70px;
  display: flex;
  justify-content: space-between;

  &-right {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
.dropdown-item {
  vertical-align: sub !important;
  display: flex !important;
  align-items: center;
  & i,
  img {
    padding-right: 3px;
  }
}
.menu-icon {
  font-size: 40px;
  color: #e3e3e3;
  cursor: pointer;
}
.mr-2 {
  float: left;
}
.toast.show {
  position: absolute;
  z-index: 10;
  margin-top: 70px;
  margin-left: 30%;
}

// Input

.form-group {
  display: flex;
  //   align-items: center;
  flex-direction: column;
  //   color: $blue;
  margin: 5px 0px;

  & > svg {
    font-size: 20px;
    margin-right: 10px;
  }
}
.event-form {
  width: 100%;
}
.custom-input .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 102, 212, 0.2);
  border-radius: 0;
}
.form-control:focus {
  border: none;
  box-shadow: 3px 3px 3px rgba(0, 102, 212, 0.2);
}

// Navigation

.nav-link {
  display: flex;
  padding: 0;
}
.nav-item-main {
  & > a {
    padding-left: 0 !important;
    text-align: center !important;
  }

  &_heading {
    padding-left: 13px;
  }
}
.nav-icon-link {
  margin-top: 15px !important;
  & i,
  img {
    // margin-left: 10%;
  }
}
.nav-icon-subitem {
  img {
    margin-right: 10px;
  }
}
.nav-main-section {
  & img {
    margin: 0 12px 4px 0 !important;
  }
}
.nav-tabs > li > a,
.nav-pills > li > a {
  color: var(--primaryText) !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: var(--primaryBg) !important;
  border: 1px solid var(--borderPrimary) !important;
  border-bottom: none !important;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #626ed4 !important;
}
.attribute-caption {
  margin: 0;
  padding: 5px;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 15px;
}
.attr-caption {
  margin: 0;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 300;
}

.attribute-wrapper {
  margin-top: 20px;
}
.nav-item {
  width: 80%;
  float: right;
  margin-right: 10px;
  cursor: pointer;
  max-width: 120px !important;
  & h4 {
    margin-left: 20%;
  }
}
[data-simplebar='true'] {
  max-width: 240px;
}
[data-simplebar='init'] {
  width: 0;
}
.logo {
  line-height: 0;
}

// SearchBar

.dropdown-wrapper {
  padding-right: 145px !important;
}

.search-bar {
  margin-top: 10px;
  height: 70px;
  display: flex;
  & span {
    cursor: pointer;
  }
  & > .dropdown-wrapper .dropdown-toggle {
    width: fit-content;
    height: fit-content;
    background-color: $white;
    border: $white;
    & > svg {
      color: #46484a;
      font-size: 32px;
      margin: 0;
    }
  }
  & #dropdown-basic {
    margin: 0;
  }
  & .input-group {
    height: fit-content;
  }
}

// Table
.table {
  color: var(--primaryText) !important;
  background-color: var(--secondaryBg);
  &-responsive {
    height: 95%;
    overflow-y: hidden;
  }
}
.table-wrapper {
  height: 90%;
  border: solid 1px #d1d1d1;
  border-radius: 4px;
  font-size: 13px;
  margin-left: 240px;
  & .filter-logo {
    font-size: 13px;
  }
  & .table-header {
    margin-right: 15px;
    margin-left: 2px;
  }
}
.table-body-wrapper {
  height: 90%;
  overflow: auto;
  overflow-x: hidden;
}
.fullGrid-wrapper {
  outline: 'none';
  overflow: 'hidden';
  height: 100%;
  font-size: 13px;
}
#fullGrid-format-select {
  // margin-bottom: 5px;
  option {
    background-color: var(--tableSecondary) !important;
  }
}
.fullGrid-thead {
  display: block;
  & tr {
    display: flex;
    border-bottom: 1px solid var(--borderPrimary);
    & div {
      padding: 0;
    }
    &* {
      th {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    & *:first-child {
      border: none !important;
      padding-left: 0;
    }
    &:first-child {
      padding-left: 0.75rem;
    }
  }
}

.fullGrid-checkbox {
  position: absolute;
  max-width: 40px !important;
}
.fullGrid-tbody {
  display: block;
  overflow: auto;
  max-height: 43rem;
  & tr {
    display: flex;
    min-height: 26px;
    & div {
      padding: 0;
    }
    & * {
      cursor: pointer;
      border: none !important;
      th {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.pdf-wrapper {
  display: none;
}
.col > span {
  cursor: pointer;
}
.table-header > .row-element {
  border: 1px solid #d6d6d6;
  padding: 1%;
  justify-content: space-between;
}

.table-column {
  border-bottom: 1px solid #d6d6d6;
  & > .colEl {
    color: $blue;
    padding: 15px;
  }
}
.react-bs-container-body td {
  color: $blue;
}
.table-bordered th {
  font-weight: 400;
  padding-right: 4%;
  padding-left: 4%;
  padding: 1%;
}
.filter-logo {
  margin: auto;
  float: right;
  font-size: 18px;
  cursor: pointer;
}
.react-bs-container-body {
  height: 72vh !important;
  overflow: scroll;
  overflow-x: hidden;
}
.sort-column {
  cursor: pointer;
}
.row {
  align-items: center;
}
.check-box {
  margin-left: 2px;
  margin-right: 10px;
}
.header-check-box {
  margin-right: 10px;
  margin-left: 0;
}
.checked {
  background-color: #f2f5f7;
}
.fullGrid-item-link {
  cursor: pointer;
}
[data-fieldtype^='primaryCdmcommRelation'] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 365px;
}
@media only screen and (max-width: 1670px) and (min-width: 1200px) {
  .table-wrapper {
    font-size: 12px;
  }
  .filter-logo {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1520px) {
  .table-wrapper {
    font-size: 11px;
  }
  .filter-logo {
    font-size: 11px;
  }
}
@media only screen and (max-width: 1200px) {
  .table-wrapper {
    font-size: 10px;
  }
  .filter-logo {
    font-size: 10px;
  }
}
@media (max-width: 5000px) {
  .container {
    max-width: 100%;
  }
}

// TextEditor

.RichEditor-root {
  color: var(--primaryText);
  font-size: 14px;
  padding-bottom: 15px;
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  margin-top: 2px;
  & .public-DraftEditor-content {
    min-height: 100px;
    overflow: auto;
  }
  & .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  & .public-DraftStyleDefault-pre {
    background-color: rgbaa(0, 0, 0, 0.05);
    font-size: 16px;
    padding: 20px;
  }
}
.DraftEditor-root {
  height: 100%;
}
.DraftEditor-editorContainer {
  height: 100%;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0;
  padding: 15px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-controls {
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  font-size: 20px;
  color: #999;
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  margin-left: 16px;
  padding: 1px 6px;
  border-radius: 4px;
  display: inline-block;
}

.RichEditor-activeButton {
  font-weight: bold;
  background-color: rgb(216, 215, 215);
}
.RichEditor-style-controls {
  margin: 15px 2px 2px !important;
}

// UserMenu
.user-dropdown {
  margin-right: 50px;
}
.dropdown-user-menu {
  padding-right: 10px;
}

.user-menu-btn {
  display: flex;
  align-items: center;
  background-color: $blue !important;
  border: 0;
  outline: none;
  box-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    background-color: $blue !important;
    outline: none;
    border: 0;
    box-shadow: none;
  }
}

.drodown-item-user-btn {
  background-color: white !important;
  outline: none;
  box-shadow: none !important;
  &:active,
  &:focus {
    color: #212529;
    background-color: white !important;
    outline: none;
  }
}
.dropleft .dropdown-item {
  border-radius: 0 !important;
}

//Dashboard

.content-wrapper {
  height: 100%;
}

.dashboard-charts-container {
  max-width: 100%;
  height: 100%;
  padding: 15px;
  &-header {
    display: flex;
    .dashboard-user-select {
      width: 90%;
      padding-left: 20px;
    }
  }
}

.list-group {
  align-items: center;
}

.dashboard-row {
  align-items: flex-start;
  height: 100%;
  padding-bottom: 70px;
  overflow: auto;
}

.dashboard-item-card-header {
  margin-bottom: 15px;
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.card-header {
  margin-bottom: 10px !important;
  border-bottom: none !important;
  & .btn {
    border: none;
    outline: none;
    &:hover {
      color: $darkGrey;
      background: none;
    }
  }
}

.dashboard-card-text {
  text-align: center;
}

.dashboard-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.dashboard-add-chart {
  background-color: $purple;
  border-color: $purple;
  margin-bottom: 15px;
}
.dashboard-tab {
  overflow: auto !important;
  & .tab-content {
    padding: 0 !important;
    margin-top: 10px;
  }
}
.dashboard-box {
  cursor: pointer;
  background-color: var(--inputPrimary);
  height: 9rem;
  margin: 5px 5px 5px 0;
  padding: 5px 19px !important;
  display: flex;
  flex-direction: column;
  & div:first-child {
    font-size: 16px;
  }
  & div:last-of-type {
    margin: auto;
    align-self: center;
    font-size: 36px;
    align-items: center;
  }

  &_container {
    padding: 0 !important;
  }
  & .row {
    min-height: 14px;
  }
}

.wrapper-dashboard-list-group {
  display: flex;
  margin-right: 10px;
  .list-group-item {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-color: $purple;
    border-right: none;
  }
}

.form-select-chart-settings {
  display: flex;
  border: 1px solid #626ed4;
  border-radius: 0.25rem;
  .chart-settings-checkbox {
    margin: 0 0.75rem;
  }
}
.card {
  background-color: var(--secondaryBg) !important;
  color: var(--primaryText) !important;
  &-header {
    background-color: var(--secondaryBg) !important;
  }
}
.dashboard-card-body {
  & table {
    border: none !important;
    & tbody {
      & tr:nth-child(odd) {
        background-color: var(--tableSecondary);
      }
    }

    overflow: auto;
    display: flex;
    flex-direction: column;
    tr {
      display: flex;
      width: 100%;
      & * {
        display: block;
        width: 100%;
        min-width: 120px;
      }
    }
    thead {
      display: flex;
    }
    tbody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 22rem;
    }
  }
}
.card-body {
  padding: 0 !important;
}

//Explore dashboard

.explore-container {
  max-height: 650px;
  overflow-x: scroll;
}

.query-dashboard-settings-form {
  margin-left: 30px;
}

.query-dashboard-settings-card-header {
  display: flex;
  align-items: center;
  padding: 0;
  .btn-setting {
    margin-left: 10px;
  }
}

.explore-settings-card {
  padding: 10px;
  &-main-settings-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard-member-group {
    display: flex;
    justify-content: flex-start;
    margin: 10px;
    &-filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 10px;
      .list-group {
        margin-bottom: 10px;
        .wrapper-dashboard-list-group {
          width: 99%;
          margin-bottom: 10px;
          .explore-dashboard-select {
            width: auto;
            margin: 0 20px;
          }
        }
      }
    }
    .dropdown-member-button {
      color: $purple;
      border-color: $purple;
      &:hover {
        color: #fff;
      }
      &:disabled {
        color: $purple;
      }
    }
  }
}

.add-source-btn {
  margin: 15px;
}

.operator-btn-settings {
  margin: 0 15px;
}

.explore-dashboard-filter-input {
  margin: 0;
}

.explore-dashboard-add-chart {
  max-width: 200px;
  margin: 15px;
}

// DetailsPage

.tabpane-content {
  align-items: start;
}
.accordion {
  color: var(--primaryText);
  font-size: 15px;
  margin-right: 5px;
  cursor: pointer;
  width: 100%;
  &-tabs-menu {
    margin-left: 1rem;
  }
  &-tab-link {
    cursor: pointer;
    margin-bottom: 9px;
    &:hover {
      color: $purple;
    }
  }
}
// MiniGrid
.minigrid-wrapper {
  & .fullGrid-topbar {
    padding-left: 14px;
  }
}
.minigrid-table {
  margin: 20px 0;
  max-width: 100%;
  border: 1px solid var(--borderPrimary);
  flex-wrap: wrap;
  overflow: auto;
  overflow-x: hidden;
  background-color: var(--secondaryBg);
  & tbody {
    min-height: 10vh;
  }
  & tr {
    border-bottom: 1px solid #e9ecef;
    position: relative;

    & .minigrid-dropdown {
      visibility: hidden;
      position: absolute;
      left: 94%;
      #dropdown-button-drop-left::before {
        display: none;
      }
    }
    &:hover .minigrid-dropdown {
      visibility: visible;
    }
  }
  & th,
  td {
    border: none !important;
    // border-bottom: none !important;
    min-height: 45px;
    text-align: start;
  }
}

// RadiosComponent
.radios-wrapper {
  width: 100%;
}
.radios-field {
  margin-left: 20px;
  display: flex;
}
label[for^='radio-'],
label[for^='text-'] {
  font-weight: normal;
}
.radio-field {
  display: flex;
  padding-left: 8.8rem;
  justify-content: space-between;
  max-width: 400px;
}
.radios-form {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 10px 0 0 20px;
  & div {
    display: flex;
    max-width: 40%;
    justify-content: space-between;
    & label {
      padding-bottom: 4px;
      cursor: pointer;
    }
    & input[type='text'] {
      max-width: 30%;
    }
  }
  & input[type='text'] {
    max-width: 30%;
  }
}
.grouped-radio-input {
  margin-top: 20px !important;
}
// FileDropzone
.dropzone {
  pointer-events: visible;
  &-container {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 100;

    & h3 {
      height: 77vh;
      display: block;
      text-align: center;
      padding: 10%;
    }
  }
  background-color: var(--secondaryBg) !important;
  color: $lightGrey !important;
}

//json-editor

.metadata-json-editor {
  display: block;
  margin-top: 20px;
  &.no-display {
    display: none;
  }
}
.metadata-selector {
  display: block;
  &.no-display {
    display: none;
  }
}

.jsoneditor-menu {
  background-color: #626ed4;
  border-bottom-color: #626ed4;
}

.error-message-container {
  background-color: #fa5f5f;
  font-weight: 400;
  color: white;
  text-align: center;
  padding: 4px;
}

//SearchWindow
.search-window-input {
  & input[type='radio'] {
    margin-bottom: 10px;
  }
}
.rbt-input-hint {
  border: none;
}
.excel-import-page {
  & input[type='checkbox'] {
    margin-top: 4px;
  }

  & .input-group {
    &-prepend {
      & span {
        color: var(--primaryText);
        background-color: var(--tableSecondary) !important;
        border-color: var(--borderPrimary);
      }
    }
    & input,
    label,
    label::after {
      color: var(--primaryText);
      background-color: var(--tableSecondary) !important;
      border-color: var(--borderPrimary);
    }
  }
}
// ExcelPreview
// .custom-file-label {
//   &::after {
//     right: auto;
//     left: 0;
//     border-right: inherit;
//     content: none !important;
//   }
// }

.file-preview {
  &-thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  &-tbody {
    z-index: 1;
  }
  &-tbody,
  &-thead {
    overflow: visible;
    & tr {
      padding-left: 0 !important;
      border: none;
      color: var(--primaryText);
      &:first-child {
        border: 1px solid var(--borderPrimary) !important;
      }
      & * {
        background-color: var(--tableSecondary) !important;
        border: 1px solid var(--borderPrimary) !important;
        & td,
        th {
          border: none !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          display: -webkit-box;
        }
        & th {
          padding-left: 3px !important;
        }
      }
    }
  }
}

//HotkeyInfo
.hotkey-info {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 102, 212, 0.1);
  }
}

.primary-button-content {
  margin-left: 8px;
  background-color: inherit !important;
}

.azure-file-manager-file-th-name-content {
  padding-left: 10px;
  background-color: inherit !important;
}

.azure-file-manager-file-th-content {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: inherit !important;
  justify-content: space-between;
  .azure-file-manager-file-name {
    background-color: inherit;
  }
}

.file-manager-tbody tr {
  padding-bottom: 5px;
}

.file-manager-custom-toogle {
  background-color: inherit !important;
  &:hover {
    background-color: #343a40 !important;
    border-color: #343a40 !important;
  }
}
.file-manager-custom-dropdown {
  position: absolute !important;
  right: 15px;
  bottom: -5px;
  background-color: inherit !important;
}

.file-manager-dropdown {
  &-item:hover,
  &-item:focus {
    background-color: #c7c9cc !important;
  }
  &-item-content {
    display: flex;
    align-items: center;
    background-color: inherit !important;
  }
}

.file-manager-tbody {
  max-height: 32rem;
}
.option-row.row-selected {
  background-color: rgba(0, 102, 212, 0.1);
}

.query-criteria-widget-card {
  height: 100%;
  &-body {
    height: 100%;
    display: flex;
    max-height: 100%;
  }

  .header-row button {
    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #4452cc;
      border-color: #3a49c9;
    }
  }
}

.header-row {
  margin-bottom: 20px;
}

.header-row button {
  margin: 0 15px;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: #4452cc;
    border-color: #3a49c9;
  }
}

.react-checkbox-tree {
  max-height: 90%;
  overflow: scroll;
  margin: 20px 0;
}

.criteria-col {
  max-height: 90%;
  overflow: scroll;
}

.query-criteria-row {
  align-items: start;
}

.button-sortable-tree.active {
  background-color: rgba(68, 82, 204, 0.5);
}

.query-builder-checkbox {
  input[type='checkbox'] {
    margin-top: 0;
  }
}

.criteria-sortable-tree-wrapper {
  height: 52%;
  width: 100%;
  float: left;
  border: solid black 1px;
}

.attribute-sortable-tree-wrapper {
  height: 100%;
  width: 80%;
  float: left;
  border: solid black 1px;
}

.sortable-tree-button {
  background-color: white;
  outline: none;
  border: none;
}

.query-result-table {
  max-height: 500px;
  border-collapse: collapse;
  overflow: scroll;
  table thead tr th {
    word-wrap: break-word;
    max-width: 150px;
  }
  table tbody tr td {
    word-wrap: break-word;
    max-width: 150px;
  }
}

.query-builder-tab-content {
  height: 95%;
}

.query-builder-tab-pane {
  height: 100%;
}

.query-entities-search-translation {
  position: absolute;
  top: 0;
  right: 0;
}

.primary-button-content {
  margin-left: 8px;
}

.nav-item-hidden {
  display: none;
}

// Documents preview
.sheet-names {
  display: none !important;
}
.doc-preview_txt {
  padding: 10px;
  border: 1px solid;
  min-height: 20rem;
}
.pg-viewer,
.spreadsheet-viewer,
.pg-viewer-wrapper {
  border: none !important;
}
.photo-viewer-container {
  margin-top: 10px !important;
  align-items: start !important;
  width: auto !important;
  height: auto !important;
}
.react-grid {
  &-Main,
  &-Grid,
  &-Canvas,
  &-Viewport {
    background-color: var(--primaryBg);
    border: none;
  }
  &-Header,
  &-HeaderCell {
    background-color: var(--secondaryBg);
    border-color: var(--borderPrimary);
    pointer-events: none;
    cursor: default;
  }
  &-Cell,
  &-Cell:hover {
    background-color: var(--tableSecondary);
    border-color: var(--borderPrimary);
  }
  &-Row:hover,
  &-Row--even:hover {
    background-color: var(--tableSecondary);
  }
}

// MailPreview
.mail-preview_wrapper {
  font-family: Segoe UI, Frutiger, Frutiger Linotype, Dejavu Sans,
    Helvetica Neue, Arial, sans-serif !important;
  padding: 5px 8px;
  border: 1px solid var(--borderPrimary);
  .mail-heading {
    padding: 18px;
    & * * {
      &:first-child {
        width: 15%;
      }
    }
  }
  .mail-body {
    margin: 50px auto 0;
    padding: 0 6px;
  }
}

//Loading screen

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .loading {
    position: relative;
    top: 50%;
    left: 50%;
  }
}
