// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

//RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

@import './icons.scss';

// Theme Custom Scss for react version
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y {
  width: auto !important;
}
.rdw-editor-main {
  border: 1px solid $card-border-color !important;
  border-radius: 4px !important;
  min-height: 130px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.dataTables_filter {
  float: right;
}

.dataTables_paginate .pagination {
  float: right;
}

.rdw-editor-wrapper {
  min-height: 180px !important;
}

.ReactModal__Overlay {
  z-index: 10001 !important;
}

.react-datepicker-wrapper {
  & input {
    padding-left: 0;
  }
  .form-control {
    width: 405% !important;
  }
}

.dropzone {
  h3 {
    text-align: center !important;
    padding-top: 100px !important;
  }
  div {
    min-height: 200px !important;
  }
  min-height: 200px !important;
}

input[type='radio'] + label {
  cursor: pointer !important;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  color: $primary !important;
}
.input-color {
  color: $input-color !important;
}
.rangeslider__fill {
  background-color: $primary !important;
}
.rating-container {
  background-color: transparent !important;
}
