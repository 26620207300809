html,
body {
  height: 100% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */

/* Handle */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.calendar-week-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 1px solid #e4e8ed;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #bac2cc;
  border-radius: 5px;
}
@media only screen and (max-width: 560px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }
}
