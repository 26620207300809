/* Pages */
//Appointment Calendar
.container-fluid {
  height: 100% !important;
  overflow: auto;
}
[data-time]:nth-child(4n-1) {
  & td {
    & span {
      display: none !important;
    }
  }
}

.fc-day-grid {
  .alert-info {
    background: var(--calendarEvent);
    color: var(--primaryText);
  }
}
.fc-time-grid .fc-today {
  background: var(--calendarEvent);
}

.calendar-theme {
  & .fc-dayGridMonth-button {
    background: url('../assets/icons/Calendar31.png') left center no-repeat;
    padding-left: 30px !important;
    background-size: 24px !important;
  }
  .fc-timeGridWeek-button {
    background: url('../assets/icons/Calendar7.png') left center no-repeat;
    padding-left: 30px !important;
    background-size: 24px !important;
  }
  .fc-timeGridDay-button {
    background: url('../assets/icons/Calendar1.png') left center no-repeat;
    padding-left: 30px !important;
    background-size: 24px !important;
  }
  &_dark {
    & .fc-dayGridMonth-button {
      background: url('../assets/icons/Calendar31_dark.png') left center
        no-repeat;
    }
    & .fc-timeGridWeek-button {
      background: url('../assets/icons/Calendar7_dark.png') left center
        no-repeat;
    }
    & .fc-timeGridDay-button {
      background: url('../assets/icons/Calendar1_dark.png') left center
        no-repeat;
    }
  }
}

// Authorization
.auth-logo {
  height: 100%;
}
.auth-form-group {
  flex-direction: row;
}
.button_wrapper {
  margin: auto auto;
}
.google-button,
.windows-button {
  border: 1px solid #d6d6d6;
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.google-button img,
.windows-button img {
  margin-right: 24px;
  width: 18px;
  height: 18px;
}

.google-button span,
.windows-button span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.account-page-full {
  height: 100%;
  overflow: auto;
  @media (max-width: 767px) {
    width: 100% !important;
  }
}
.card-block_auth {
  background-color: #fff;
  color: $textLight;
}

.fc-toolbar {
  @media (max-width: 767px) {
    .fc-left,
    .fc-right {
      margin-top: 40px;
    }
    display: block;
    text-align: center;
    .fc-center {
      & h2 {
        font-size: 1.55em;
      }
      position: absolute;
      padding-bottom: 44px;
    }
    display: flex;
  }
}
// ContactAdministrator

.contactAdministrator__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

// DetailsPage
.editPage-container {
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 50px;
  overflow-y: scroll;
  &:focus {
    outline: none !important;
  }
}

.modal-header-text {
  border-color: var(--borderPrimary) !important;
}

.modal-header-row {
  background-color: var(--secondaryBg);
  margin-top: 0;
  padding: 5px 25px;
  margin-bottom: 10px;
}
.modal-body {
  padding: 0;
}
.modal-header-row > .btn-primary {
  border: none;
  width: fit-content;
  margin-right: 15px;
  & i {
    font-size: 15px;
  }
}

.display-more {
  width: 70%;
}
.tab-heading {
  display: flex !important;
  align-items: center;
  i,
  img {
    margin-right: 3px;
  }
}
@media only screen and (min-width: 1023px) {
  .tab-component {
    padding-right: 40px !important;
  }
}
.list-group {
  margin: 15px 10px;
  & a {
    background-color: var(--tableSecondary);
    color: var(--primaryText);
  }
  &-tabs {
    align-items: flex-start;
    overflow: auto;
  }
  &-item.active {
    background-color: var(--tableSecondary) !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    color: $purple !important;
  }
}
.tabContent_doc-preview {
  padding: 1.5rem;
}
.edit-wrapper {
  margin: 20px;
}
.editData-row {
  margin: 5px 0;
  align-items: center;
}

.select-input {
  padding: 2px 15px;
}
.select-option {
  height: 200px;
}
.checkbox-group {
  margin: 10px auto;
  display: flex;
  justify-content: space-around;
  width: 30%;
}
.form-control:focus {
  border: #66686996 1px solid;
}
.details-fields {
  margin-bottom: 15px;
  cursor: text;
  align-items: start;
  justify-content: start;
  padding: 0 !important;
  & label {
    font-weight: inherit;
    & input[type='checkbox'] {
      margin-right: 5px;
      vertical-align: middle;
      position: relative;
      bottom: 1px;
    }
  }
}

.details-notes-textArea {
  border: $blue 1px solid;
  height: 180px;
  padding: 5px;
}
.detail-Cdmcomm-field {
  white-space: pre-wrap;
  word-break: break-word;
  // padding: 5px;
}
.input-checkbox {
  margin-left: 20%;
}
.edit-icon {
  cursor: pointer;
  display: block;
  width: 25px;
  margin: 0 0 6px 5%;
  height: 25px;
  background-size: 25px 25px;
  & :hover {
    fill: $blue;
  }
}

.svg-fill :first-child {
  fill: $blue;
}
.btn-editing {
  display: none;
}

// Excel Page

.excel-import-page {
  padding: 50px;
}
.import-settings {
  margin-bottom: 20px;
}
.ExcelTable2007 th,
.ExcelTable2007 td {
  background-color: #fdfdfd;
  border: 1px solid #bfbfbf;
  text-align: center;
  padding: 5px;
}
.ExcelTable2007 th,
.ExcelTable2007 .heading {
  background-color: #f1f1f1;
  border-bottom-color: #9b9b9b;
}
.ExcelTable2007 .heading {
  margin-left: -20px;
}
.error-message {
  color: #fa5f5f;
  font-weight: 400;
}

// FullGrid
.fullGrid-topbar {
  // justify-content: space-between;
  margin: 13px 0;
  padding-left: 24px;
  width: 100%;
}
.btn-primary {
  color: var(--primaryText) !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  &:hover {
    background-color: #d1d1d15b !important;
  }
  & .active,
  &:focus {
    border-color: #ffffff00 !important;
    background-color: #d1d1d15b !important;
    box-shadow: none !important;
  }
}
.toolbar-button {
  color: var(--primaryText) !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important;
  padding: 0;
  & i {
    vertical-align: sub;
    margin-right: 3px;
  }
}

.body {
  display: flex;
  overflow: hidden;
  height: 100%;
  .simpleBar {
    #sidebar-menu {
      height: 100% !important;
      overflow-y: auto !important;
    }
  }
}
#table-fullGrid {
  height: 93%;
  width: 99%;
  @media (min-height: 1300px) {
    height: 98%;
  }
  @media (min-height: 1960px) {
    height: 100%;
  }
}

.fullGrid-tbody,
.fullGrid-thead {
  input[type='checkbox'] {
    visibility: hidden;
  }
  label {
    cursor: pointer;
    border: none !important;
  }

  input[type='checkbox'] + label:before {
    border: 1px solid var(--borderPrimary);
    content: '\00a0';
    display: inline-block;
    font: 14px/1em sans-serif;
    height: 16px;
    margin: 0 0.25em 0 1em;
    vertical-align: text-bottom;
    text-align: center;

    width: 16px;
    border-radius: 100px;
  }
  input[type='checkbox']:disabled + label:before {
    border: 1px solid var(--borderPrimary);
  }

  input[type='checkbox']:checked + label:before {
    color: $white;
    background-color: #0075ff;
    text-align: center;
    font-family: 'Material Design Icons';
    content: '\f12c';
  }
  input[type='checkbox']:checked + label:after {
    font-weight: bold;
    background: none !important;
  }
}
.fullGrid_row-selector {
  & th {
    padding-right: 2px !important;
  }
}
.detailes-for-phone {
  max-height: 100%;
  overflow: auto;
}
.detailed-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 8rem;
  border: 2px solid var(--borderPrimary);
  &-center {
    font-weight: bold;
  }
}
.fullGrid {
  display: block !important;
  overflow: auto;
  table-layout: fixed;
  & i {
    background-color: inherit !important;
  }
  & th,
  tr:hover {
    & label {
      visibility: visible;
    }
  }
  & th,
  tr label {
    & label {
      visibility: hidden;
    }

    & input:checked + label {
      visibility: visible;
    }
    border: none !important;
    background-color: inherit;
  }

  &-thead {
    position: -webkit-sticky;
    background-color: var(--tableSecondary);
    border-bottom: 1.8px solid var(--borderPrimary);
    position: sticky;
    top: 0;
    z-index: 4;
  }
  &-striped {
    & tr:nth-child(odd) {
      & .table-cell {
        background-color: var(--tableSecondary);
      }
      background-color: var(--tableSecondary);
      & th {
        background-color: var(--tableSecondary);
      }
    }
  }

  &-tbody {
    z-index: 1;
    & th {
      padding: 4px 2px !important;
    }
  }
  &-tbody,
  &-thead {
    overflow: visible;

    & tr {
      padding-left: 0 !important;
      border: none;
      color: var(--primaryText);
      background-color: var(--secondaryBg);
      & * {
        background-color: var(--secondaryBg);
        & td,
        th {
          display: block !important;
          background-color: var(--secondaryBg);
          text-overflow: ellipsis;
          display: -webkit-box;
          overflow: hidden;
          white-space: nowrap;
        }
        & th {
          padding-left: 3px !important;
        }
      }
    }
  }
  &-mobile-cell {
    margin-left: 15px !important;
    & * {
      min-height: 45px;
    }
    & th {
      border-right: 2px solid var(--borderPrimary) !important;
    }
    & div:last-child th {
      padding-left: 15px !important;
    }
  }
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1023px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td {
      display: block;
    }

    tr {
      display: flex;
      flex-wrap: nowrap;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  }
}
.search-bar {
  .input-group span {
    background-color: var(--inputPrimary);
    border-color: var(--borderPrimary);
  }
  .dropdown {
    &-menu .show {
      border-color: var(--borderPrimary);
    }
    button {
      background-color: var(--tableSecondary) !important;
      border-color: var(--borderPrimary) !important;
      color: var(--primaryText) !important;
    }
  }
}
