//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark {
  color: $gray-200 !important;
}

.btn-outline-dark {
  &:hover {
    color: $gray-200 !important;
  }
}
