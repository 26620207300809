@import '../../scss/colors';
.editable-fields {
  .edit-input-fields {
    input:not([type='checkbox']),
    input:not([type='radio']),
    & textarea {
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 25px;
      outline: none;
      cursor: text;
      border: none;
      border-radius: 0;
      border-bottom: $blue 1px solid;
    }
    .DraftEditor-editorContainer {
      border: $blue 1px solid;
    }
    .row {
      margin-block: unset;
    }
  }
}
.rendered-field {
  &,
  &_textAreaPlain {
    all: unset !important;
    border: $blue 1px solid !important;
    width: 100% !important;
  }
}
