//
// Form editors.scss
//

// Tinymce

.tox-tinymce {
  border: 1px solid $gray-300 !important;
}

.tox {
  .tox-statusbar {
    border-top: 1px solid $gray-300 !important;
  }
  .tox-menubar,
  .tox-edit-area__iframe,
  .tox-statusbar {
    background-color: $card-bg !important;
    background: none !important;
  }
  .tox-mbtn {
    color: $gray-700 !important;
    &:hover:not(:disabled):not(.tox-mbtn--active) {
      background-color: $gray-300 !important;
    }
  }
  .tox-tbtn {
    &:hover {
      background-color: $gray-300 !important;
    }
  }

  .tox-toolbar,
  .tox-toolbar__overflow,
  .tox-toolbar__primary {
    background: $gray-300 !important;
  }

  .tox-tbtn {
    color: $gray-700 !important;
    svg {
      fill: $gray-700 !important;
    }
  }

  .tox-edit-area__iframe {
    background-color: $card-bg !important;
  }

  .tox-statusbar a,
  .tox-statusbar__path-item,
  .tox-statusbar__wordcount {
    color: $gray-700 !important;
  }

  &:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
    border-right: 1px solid darken($gray-300, 5%) !important;
  }
}

// Summernote

.note-editor {
  &.note-frame {
    border: 1px solid $gray-300;
    box-shadow: none;
    margin: 0;

    .note-statusbar {
      background-color: $gray-300;
      border-top: 1px solid $gray-300;
    }

    .note-editing-area {
      .note-editable,
      .note-codable {
        border: none;
        color: $gray-500;
        background-color: transparent;
      }
    }
  }
}

.note-btn-group {
  .note-btn {
    background-color: $gray-300 !important;
    border-color: $gray-300 !important;
  }
}

.note-status-output {
  display: none;
}

.note-editable {
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.note-popover .popover-content .note-color .dropdown-menu,
.card-header.note-toolbar .note-color .dropdown-menu {
  min-width: 344px;
}

.note-popover {
  border-color: $gray-300;
}

.note-popover .popover-content,
.card-header.note-toolbar {
  background-color: $gray-300;
}

.note-toolbar {
  padding: 0 0 5px 5px !important;
}

// custom css for react
.rdw-editor-toolbar {
  background-color: $card-cap-bg;
  border-color: $card-cap-bg;
}

.rdw-dropdown-wrapper,
.rdw-option-wrapper {
  background-color: $light !important;
  border-color: $light !important;
  box-shadow: none !important;
}

.rdw-editor-main {
  border: 1px solid $card-cap-bg !important;
  padding: 0 10px;
}
.rdw-dropdownoption-default {
  color: $gray-500;
}
