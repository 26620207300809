@import './scss/colors.scss';

html {
  scroll-behavior: smooth;
}
#root,
.App {
  height: 100%;
}
body {
  overflow: hidden;
  height: 100%;
  background-color: var(--primaryBg);
  color: var(--primaryText);
}

.wrapper {
  width: 100%;
  margin-left: 250px;
  padding-top: 70px;
  overflow: hidden;
  @media (max-width: 1023px) {
    margin: 20px 5px 0;
  }
}

input,
select,
select option {
  background-color: var(--primaryInput) !important;
  border-color: var(--borderPrimary) !important;
  color: var(--primaryText) !important;
}

table,
tr,
th,
td {
  border-color: var(--borderPrimary) !important;
}
a {
  cursor: pointer;
}
.pdf-logo {
  color: red;
  display: flex;
  justify-content: space-around;
}
