$white: #fff; // Used as text color and background color of some components
$black: #000; // text color only
$blue: #0066d4; // Used as background of main header and modal header; Text color in some components
$purple: #626ed4;
$green: #02a499;

$backGroundWhite: #f2f5f7; // Used as background and border color inside AppointMentCalendar elements
$lightGrey: #46484a; // Used as font color inside AppointmentCalendar elements
$darkGrey: #212529;
$bgLight: #f8f8fa;
$bgDark: #222736;
$textLight: #5b626b;
$textDark: #b4c7d9;
$secondaryDark: #2a3142;
$additionalDark: #32394e;

$inputDark: #2e3648;
$inputLight: $white;
$borderDark: #363e54;
$borderLight: #ced4da;
$tableSecondaryDark: #32394e;
$tableSecondaryLight: #f8f9fa;

$alertModal: rgba(250, 95, 95, 0.3);
$alertError: #fa5f5f;
$alertWarning: #faaf5f;
$disabledText: rgb(151, 162, 162);
$greyed: #5a9ed1;
$calendarEventLight: #fffadf8a;

$iconDefault: #212121;

:root {
  --bgLight: #f8f8fa;
  --bgDark: #222736;
  --primaryBg: #f8f8fa;
  --primaryText: #5b626b;
  --secondaryBg: #fff;
  --tableSecondary: #f8f9fa;
  --inputPrimary: #fff;
  --borderPrimary: #ced4da;
  --calendarEvent: #fffadf8a;
  --iconDefault: #212121;
}

:export {
  disabledText: $disabledText;
  bgLight: $bgLight;
  bgDark: $bgDark;
  textLight: $textLight;
  textDark: $textDark;
  secondaryLight: $white;
  secondaryDark: $secondaryDark;
  tableSecondaryLight: $tableSecondaryLight;
  tableSecondaryDark: $tableSecondaryDark;
  borderDark: $borderDark;
  borderLight: $borderLight;
  inputDark: $inputDark;
  inputLight: $inputLight;
  additionalDark: $additionalDark;
  calendarEventLight: $calendarEventLight;
  iconDefault: $iconDefault;
}
