//
// calendar.scss
//
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/bootstrap/main.css';

.fc-toolbar {
  h2 {
    line-height: 30px;
    text-transform: uppercase;
  }
}

.fc {
  th.fc-widget-header {
    background: $gray-200;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: $font-weight-semibold;
  }
}

.fc-unthemed {
  .fc-content,
  .fc-divider,
  .fc-list-heading td,
  .fc-list-view,
  .fc-popover,
  .fc-row,
  tbody,
  td,
  th,
  thead {
    border-color: $gray-200;
  }
  td.fc-today {
    background: $gray-300;
  }
  th.fc-day-header {
    height: 21px !important;
  }
}

.fc-button {
  background: $card-bg;
  border-color: $border-color;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px !important;
  padding: 5px 5px !important;
  text-align: center;
}

.fc-event,
.fc-event-dot {
  background-color: $primary;
}

.fc-event .fc-content {
  color: $white;
}
