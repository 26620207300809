@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?bhz1n8');
  src: url('../fonts/icomoon.eot?bhz1n8#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?bhz1n8') format('truetype'),
    url('../fonts/icomoon.woff?bhz1n8') format('woff'),
    url('../fonts/icomoon.svg?bhz1n8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account .path1:before {
  content: '\e900';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-account .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-act_exchangereplicate .path1:before {
  content: '\e902';
  color: var(--iconDefault);
}
.icon-act_exchangereplicate .path2:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-act_highimportance:before {
  content: '\e904';
}
.icon-act_history:before {
  content: '\e905';
}
.icon-act_relations .path1:before {
  content: '\e906';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-act_relations .path2:before {
  content: '\e907';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-act_sensitivity:before {
  content: '\e908';
}
.icon-activity:before {
  content: '\e909';
}
.icon-addflow_diagram .path1:before {
  content: '\e90a';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-addflow_diagram .path2:before {
  content: '\e90b';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-address .path1:before {
  content: '\e90c';
  color: var(--iconDefault);
  opacity: 0.5;
}
.icon-address .path2:before {
  content: '\e90d';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-address .path3:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(49, 60, 158);
}
.icon-address .path4:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-aligncenter:before {
  content: '\e910';
}
.icon-alignjustified:before {
  content: '\e911';
}
.icon-alignleft:before {
  content: '\e912';
}
.icon-alignright:before {
  content: '\e913';
}
.icon-allowances:before {
  content: '\e914';
}
.icon-answer:before {
  content: '\e915';
}
.icon-appointment .path1:before {
  content: '\e916';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-appointment .path2:before {
  content: '\e917';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-appointment .path3:before {
  content: '\e918';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-appointment .path4:before {
  content: '\e919';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-approvePurchaseInvoice .path1:before {
  content: '\e91a';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-approvePurchaseInvoice .path2:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-approvePurchaseInvoice .path3:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-arrow_down_blue:before {
  content: '\e91d';
}
.icon-arrow_up_blue:before {
  content: '\e91e';
}
.icon-assignvalue .path1:before {
  content: '\e91f';
  color: var(--iconDefault);
}
.icon-assignvalue .path2:before {
  content: '\e920';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-assignvalue .path3:before {
  content: '\e921';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-attachment:before {
  content: '\e922';
}
.icon-automation:before {
  content: '\e923';
}
.icon-b:before {
  content: '\e924';
  color: #005dba;
}
.icon-bank:before {
  content: '\e925';
}
.icon-bold:before {
  content: '\e926';
}
.icon-bouncedmail .path1:before {
  content: '\e927';
  color: rgb(197, 11, 23);
}
.icon-bouncedmail .path2:before {
  content: '\e928';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bouncedmail .path3:before {
  content: '\e929';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-brick:before {
  content: '\e92a';
}
.icon-building-line:before {
  content: '\e92b';
}
.icon-bulletlist:before {
  content: '\e92c';
}
.icon-businessunit:before {
  content: '\e92d';
}
.icon-buttonDropDownAttach:before {
  content: '\e92e';
}
.icon-buttonDropDownDelete:before {
  content: '\e92f';
}
.icon-buttonDropDownDetails:before {
  content: '\e930';
}
.icon-buttonDropDownNew:before {
  content: '\e931';
  color: #996f00;
}
.icon-buttonDropDownPlay:before {
  content: '\e932';
  color: #1f801f;
}
.icon-buttonDropDownSelect:before {
  content: '\e933';
}
.icon-buttonDropDownSelectV5:before {
  content: '\e934';
}
.icon-buttonDropDownStop .path1:before {
  content: '\e935';
  color: rgb(197, 11, 23);
  opacity: 0.75;
}
.icon-buttonDropDownStop .path2:before {
  content: '\e936';
  margin-left: -1em;
  color: rgb(197, 11, 23);
}
.icon-calendar .path1:before {
  content: '\e937';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-calendar .path2:before {
  content: '\e938';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-campaign:before {
  content: '\e939';
}
.icon-campaign_bounce .path1:before {
  content: '\e93a';
  color: var(--iconDefault);
}
.icon-campaign_bounce .path2:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-campaign_chart .path1:before {
  content: '\e93c';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-campaign_chart .path2:before {
  content: '\e93d';
  margin-left: -1em;
  color: rgb(0, 119, 160);
}
.icon-campaign_chart .path3:before {
  content: '\e93e';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-campaign_click:before {
  content: '\e93f';
  color: #005dba;
}
.icon-campaign_contactpreference:before {
  content: '\e940';
}
.icon-campaign_document:before {
  content: '\e941';
}
.icon-campaign_gauge .path1:before {
  content: '\e942';
  color: var(--iconDefault);
}
.icon-campaign_gauge .path2:before {
  content: '\e943';
  margin-left: -1em;
  color: rgb(197, 11, 23);
}
.icon-campaign_gauge .path3:before {
  content: '\e944';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-campaign_gauge .path4:before {
  content: '\e945';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-campaign_gear:before {
  content: '\e946';
}
.icon-campaign_mail_preferences .path1:before {
  content: '\e947';
  color: var(--iconDefault);
}
.icon-campaign_mail_preferences .path2:before {
  content: '\e948';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-campaign_mail_preferences .path3:before {
  content: '\e949';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-campaign_marketingplan:before {
  content: '\e94a';
}
.icon-campaign_subjects:before {
  content: '\e94b';
}
.icon-check:before {
  content: '\e94c';
}
.icon-closeWindow .path1:before {
  content: '\e94d';
  color: var(--iconDefault);
}
.icon-closeWindow .path2:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-coins:before {
  content: '\e94f';
}
.icon-company:before {
  content: '\e950';
}
.icon-compile:before {
  content: '\e951';
  color: #996f00;
}
.icon-consolidate .path1:before {
  content: '\e952';
  color: rgb(0, 93, 186);
}
.icon-consolidate .path2:before {
  content: '\e953';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-consolidate .path3:before {
  content: '\e954';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-contact .path1:before {
  content: '\e955';
  color: var(--iconDefault);
}
.icon-contact .path2:before {
  content: '\e956';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-contactFunction:before {
  content: '\e957';
}
.icon-contract:before {
  content: '\e958';
}
.icon-copy .path1:before {
  content: '\e959';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-copy .path2:before {
  content: '\e95a';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-count:before {
  content: '\e95b';
}
.icon-crosshair:before {
  content: '\e95c';
}
.icon-customerTask:before {
  content: '\e95d';
}
.icon-cut .path1:before {
  content: '\e95e';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-cut .path2:before {
  content: '\e95f';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-cut .path3:before {
  content: '\e960';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-cut .path4:before {
  content: '\e961';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-datauniverse .path1:before {
  content: '\e962';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-datauniverse .path2:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-definitionPublish .path1:before {
  content: '\e964';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-definitionPublish .path2:before {
  content: '\e965';
  margin-left: -1em;
  color: rgb(0, 103, 88);
}
.icon-definitionPublish .path3:before {
  content: '\e966';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-definitionPublish .path4:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-delete:before {
  content: '\e968';
}
.icon-details .path1:before {
  content: '\e969';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-details .path2:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-details .path3:before {
  content: '\e96b';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-dialout .path1:before {
  content: '\e96c';
  color: rgb(0, 93, 186);
}
.icon-dialout .path2:before {
  content: '\e96d';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-dunning .path1:before {
  content: '\e96e';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-dunning .path2:before {
  content: '\e96f';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-encryption .path1:before {
  content: '\e970';
  color: var(--iconDefault);
}
.icon-encryption .path2:before {
  content: '\e971';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-equipment:before {
  content: '\e972';
}
.icon-f:before {
  content: '\e973';
  color: #005dba;
}
.icon-favorites:before {
  content: '\e974';
  color: #996f00;
}
.icon-filter:before {
  content: '\e975';
}
.icon-forecast:before {
  content: '\e976';
}
.icon-forecastTasks:before {
  content: '\e977';
}
.icon-functions:before {
  content: '\e978';
}
.icon-generic_log .path1:before {
  content: '\e979';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-generic_log .path2:before {
  content: '\e97a';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-generic_log .path3:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(0, 103, 88);
}
.icon-globe:before {
  content: '\e97c';
  color: #005dba;
}
.icon-graph_pie .path1:before {
  content: '\e97d';
  color: rgb(0, 119, 160);
}
.icon-graph_pie .path2:before {
  content: '\e97e';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-help:before {
  content: '\e97f';
}
.icon-helpdesk:before {
  content: '\e980';
}
.icon-image .path1:before {
  content: '\e981';
  color: var(--iconDefault);
}
.icon-image .path2:before {
  content: '\e982';
  margin-left: -1em;
  color: rgb(153, 111, 0);
}
.icon-image .path3:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-import:before {
  content: '\e984';
}
.icon-insertTable .path1:before {
  content: '\e985';
  color: var(--iconDefault);
}
.icon-insertTable .path2:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-italic:before {
  content: '\e987';
}
.icon-line-chart .path1:before {
  content: '\e988';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-line-chart .path2:before {
  content: '\e989';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-line-chart .path3:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(0, 119, 160);
}
.icon-link .path1:before {
  content: '\e98b';
  color: rgb(0, 119, 160);
}
.icon-link .path2:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(105, 54, 170);
}
.icon-listbrow .path1:before {
  content: '\e98d';
  color: var(--iconDefault);
  opacity: 0.25;
}
.icon-listbrow .path2:before {
  content: '\e98e';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-listbrow .path3:before {
  content: '\e98f';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-listbrow .path4:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-listbrow .path5:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mail:before {
  content: '\e992';
}
.icon-mail_code:before {
  content: '\e993';
}
.icon-mailchimp:before {
  content: '\e994';
}
.icon-mailing .path1:before {
  content: '\e995';
  color: var(--iconDefault);
}
.icon-mailing .path2:before {
  content: '\e996';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-mailing .path3:before {
  content: '\e997';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-mailing .path4:before {
  content: '\e998';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-manufacturer:before {
  content: '\e999';
}
.icon-map .path1:before {
  content: '\e99a';
  color: var(--iconDefault);
  opacity: 0.5;
}
.icon-map .path2:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-map .path3:before {
  content: '\e99c';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-meeting_tentative:before {
  content: '\e99d';
}
.icon-meter:before {
  content: '\e99e';
}
.icon-minibrick:before {
  content: '\e99f';
}
.icon-multicode .path1:before {
  content: '\e9a0';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-multicode .path2:before {
  content: '\e9a1';
  margin-left: -1em;
  color: rgb(0, 119, 160);
}
.icon-multicode .path3:before {
  content: '\e9a2';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-new:before {
  content: '\e9a3';
}
.icon-note:before {
  content: '\e9a4';
}
.icon-numberedlist:before {
  content: '\e9a5';
}
.icon-partslist:before {
  content: '\e9a6';
}
.icon-party:before {
  content: '\e9a7';
}
.icon-pharma:before {
  content: '\e9a8';
}
.icon-product:before {
  content: '\e9a9';
}
.icon-project:before {
  content: '\e9aa';
}
.icon-purchaseinvoice:before {
  content: '\e9ab';
}
.icon-purchaseOrder:before {
  content: '\e9ac';
}
.icon-rating:before {
  content: '\e9ad';
  color: #996f00;
}
.icon-rdp .path1:before {
  content: '\e9ae';
  color: var(--iconDefault);
}
.icon-rdp .path2:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-rdp .path3:before {
  content: '\e9b0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-recept:before {
  content: '\e9b1';
}
.icon-region .path1:before {
  content: '\e9b2';
  color: rgb(0, 93, 186);
}
.icon-region .path2:before {
  content: '\e9b3';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-reload:before {
  content: '\e9b4';
  color: #005dba;
}
.icon-remoteSupport .path1:before {
  content: '\e9b5';
  color: var(--iconDefault);
}
.icon-remoteSupport .path2:before {
  content: '\e9b6';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-report:before {
  content: '\e9b7';
}
.icon-salesbudget:before {
  content: '\e9b8';
}
.icon-save .path1:before {
  content: '\e9b9';
  color: rgb(0, 93, 186);
}
.icon-save .path2:before {
  content: '\e9ba';
  margin-left: -1em;
  color: rgb(0, 93, 186);
  opacity: 0.75;
}
.icon-saveAndClose .path1:before {
  content: '\e9bb';
  color: rgb(0, 93, 186);
}
.icon-saveAndClose .path2:before {
  content: '\e9bc';
  margin-left: -1em;
  color: rgb(0, 93, 186);
  opacity: 0.75;
}
.icon-saveAndClose .path3:before {
  content: '\e9bd';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-saveAndClose .path4:before {
  content: '\e9be';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-savedCollection .path1:before {
  content: '\e9bf';
  color: var(--iconDefault);
}
.icon-savedCollection .path2:before {
  content: '\e9c0';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-savedCollection .path3:before {
  content: '\e9c1';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-search:before {
  content: '\e9c2';
}
.icon-security_role:before {
  content: '\e9c3';
}
.icon-segment .path1:before {
  content: '\e9c4';
  color: rgb(0, 93, 186);
}
.icon-segment .path2:before {
  content: '\e9c5';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-segment .path3:before {
  content: '\e9c6';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-segment .path4:before {
  content: '\e9c7';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-server_job .path1:before {
  content: '\e9c8';
  color: var(--iconDefault);
}
.icon-server_job .path2:before {
  content: '\e9c9';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-server_job .path3:before {
  content: '\e9ca';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-server_task:before {
  content: '\e9cb';
}
.icon-settings:before {
  content: '\e9cc';
}
.icon-showGrid:before {
  content: '\e9cd';
}
.icon-signature .path1:before {
  content: '\e9ce';
  color: rgb(0, 93, 186);
}
.icon-signature .path2:before {
  content: '\e9cf';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-signature .path3:before {
  content: '\e9d0';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-smartphone:before {
  content: '\e9d1';
}
.icon-sort .path1:before {
  content: '\e9d2';
  color: var(--iconDefault);
}
.icon-sort .path2:before {
  content: '\e9d3';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-sortAscending .path1:before {
  content: '\e9d4';
  color: var(--iconDefault);
}
.icon-sortAscending .path2:before {
  content: '\e9d5';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-sortDescending .path1:before {
  content: '\e9d6';
  color: var(--iconDefault);
}
.icon-sortDescending .path2:before {
  content: '\e9d7';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-task .path1:before {
  content: '\e9d8';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-task .path2:before {
  content: '\e9d9';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-task .path3:before {
  content: '\e9da';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-template:before {
  content: '\e9db';
}
.icon-timetracking:before {
  content: '\e9dc';
}
.icon-tm_mark_done .path1:before {
  content: '\e9dd';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-tm_mark_done .path2:before {
  content: '\e9de';
  margin-left: -1em;
  color: rgb(31, 128, 31);
}
.icon-tm_mark_done .path3:before {
  content: '\e9df';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-tm_recall .path1:before {
  content: '\e9e0';
  color: var(--iconDefault);
}
.icon-tm_recall .path2:before {
  content: '\e9e1';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-tm_recall .path3:before {
  content: '\e9e2';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-todo:before {
  content: '\e9e3';
}
.icon-underline:before {
  content: '\e9e4';
}
.icon-undo:before {
  content: '\e9e5';
}
.icon-univers_currencies .path1:before {
  content: '\e9e6';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-univers_currencies .path2:before {
  content: '\e9e7';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_currencies .path3:before {
  content: '\e9e8';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_date-time .path1:before {
  content: '\e9e9';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-univers_date-time .path2:before {
  content: '\e9ea';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_date-time .path3:before {
  content: '\e9eb';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_date-time .path4:before {
  content: '\e9ec';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_mail:before {
  content: '\e9ed';
}
.icon-univers_marketing:before {
  content: '\e9ee';
}
.icon-univers_phone .path1:before {
  content: '\e9ef';
  color: rgb(0, 103, 88);
}
.icon-univers_phone .path2:before {
  content: '\e9f0';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-univers_phone .path3:before {
  content: '\e9f1';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-uploadeddocument .path1:before {
  content: '\e9f2';
  color: rgb(0, 93, 186);
}
.icon-uploadeddocument .path2:before {
  content: '\e9f3';
  margin-left: -1em;
  color: var(--iconDefault);
}
.icon-user:before {
  content: '\e9f4';
}
.icon-users:before {
  content: '\e9f5';
}
.icon-varebestilling:before {
  content: '\e9f6';
}
.icon-vCard:before {
  content: '\e9f7';
}
.icon-verticalline:before {
  content: '\e9f8';
}
.icon-voucher:before {
  content: '\e9f9';
}
.icon-warehouse:before {
  content: '\e9fa';
}
.icon-warning .path1:before {
  content: '\e9fb';
  color: rgb(153, 111, 0);
}
.icon-warning .path2:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-zoomIn .path1:before {
  content: '\e9fd';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomIn .path2:before {
  content: '\e9fe';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomIn .path3:before {
  content: '\e9ff';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-zoomOut .path1:before {
  content: '\ea00';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomOut .path2:before {
  content: '\ea01';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomOut .path3:before {
  content: '\ea02';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
.icon-zoomToFit .path1:before {
  content: '\ea03';
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomToFit .path2:before {
  content: '\ea04';
  margin-left: -1em;
  color: var(--iconDefault);
  opacity: 0.75;
}
.icon-zoomToFit .path3:before {
  content: '\ea05';
  margin-left: -1em;
  color: rgb(0, 93, 186);
}
